import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import PreviewCompatibleImage  from '../components/PreviewCompatibleImage'

export const BlogPostTemplate = ({
  content,
  contentComponent,
  date,
  description,
  featuredImage,
  tags,
  title,
  helmet,
}) => {
  const PostContent = contentComponent || Content

  return (
    <section className="section">
      {helmet || ''}
      <div className="container content">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
              {title}
            </h1>
            <h3 className="subtitle is-size-5">
              {date}
            </h3>
            {featuredImage || ''}
            <PostContent content={content} className="blog-content" />
            {tags && tags.length ? (
              <div style={{ marginTop: `4rem` }}>
                <h4>Tags</h4>
                <ul className="taglist">
                  {tags.map(tag => (
                    <li key={tag + `tag`}>
                      <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  )
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  date: PropTypes.string,
  description: PropTypes.string,
  featuredImage: PropTypes.object,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

const BlogPost = ({ data }) => {
  const { 
    markdownRemark: post,
    site: {
      siteMetadata: { siteUrl },
    },
  } = data

  const featuredImage = post.featuredRemoteImage ? post.featuredRemoteImage : post.frontmatter.featuredimage ? post.frontmatter.featuredimage : null


  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        date={post.frontmatter.date}
        description={post.frontmatter.description}
        featuredImage={
          featuredImage ? 
          <div className='featured-image'>
            <PreviewCompatibleImage
              imageInfo={{
                image: featuredImage,
                alt: post.frontmatter.featuredImgAlt ? post.frontmatter.featuredImgAlt : `featured image for post ${
                  post.frontmatter.title
                }`,
              }} 
            /> 
           </div> : null
        }
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
            <meta property="og:type" content="article"/>
            <meta property="og:title" content={post.frontmatter.title} />
            <meta property="og:description" content={post.frontmatter.description} />
            <meta property="og:image" content={ 
              featuredImage ? `${siteUrl}${featuredImage.childImageSharp.fluid.src}`: `${siteUrl}/img/clifton-suspension-bridge.jpg`
            } />
            {post.frontmatter.featuredImgAlt && <meta property="og:image:alt" content={post.frontmatter.featuredImgAlt} />}
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
      />
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      featuredRemoteImage{
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        featuredImgAlt
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
